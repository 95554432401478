import { dispatch } from "~/old-app/redux";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import { Country, CountryCity, CountryState, District } from "./country-types";
import { ConfigActions } from "~/old-app/redux/config-slice";
import { getCookie } from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";

export const CountryApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    countries: builder.query<Response<Country[]>, void>({
      providesTags: ["countries"],
      query: () => ({
        url: "/countries/main",
        method: "GET",
      }),

      transformResponse: (response: Response<Country[]>) => {
        dispatch(ConfigActions.setCountries(response?.response?.data || []));
        return response;
      },
    }),
    allCountries: builder.query<Response<Country[]>, void>({
      providesTags: ["all-countries"],
      query: () => ({
        url: "/countries?limit=1000",
        method: "GET",
      }),
    }),
    districts: builder.query<Response<District[]>, void>({
      providesTags: ["districts"],
      query: () => ({
        url: "/districts/main",
        method: "GET",
      }),

      transformResponse: (response: Response<District[]>) => {
        const districtId = getCookie(CookieEnum.DistrictId);
        const district =
          response?.response?.data?.find(
            (e) => e.id + "" === districtId + ""
          ) || response?.response?.data?.[0];
        // if (district) {
        dispatch(ConfigActions.setDistrict(district));
        // }
        return response;
      },
    }),

    countryState: builder.query<Response<CountryState>, string | undefined>({
      providesTags: (result, error, id) => [{ type: "country-states", id }],
      query: (id) => ({
        url: `/country/${id}/states`,
        method: "GET",
        params: {
          page: 1,
          limit: 10000,
        },
      }),
    }),
    stateCities: builder.query<Response<CountryCity>, string | undefined>({
      providesTags: (result, error, id) => [{ type: "country-cities", id }],
      query: (id) => ({
        url: `/state/${id}/cities`,
        method: "GET",
        params: {
          page: 1,
          limit: 10000,
        },
      }),
    }),
    cities: builder.query<Response<CountryCity>, void>({
      providesTags: ["cities"],
      query: () => ({
        url: `/cities`,
        method: "GET",
        params: {
          page: 1,
          limit: 10000,
        },
      }),
    }),
  }),
});

export const {
  useCountriesQuery,
  useStateCitiesQuery,
  useCountryStateQuery,
  useCitiesQuery,
  useDistrictsQuery,
  useAllCountriesQuery,
} = CountryApi;
