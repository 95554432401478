import { useConfigSlice } from "~/old-app/redux/config-slice";
import {
  useCountriesQuery,
  useStateCitiesQuery,
  useCountryStateQuery,
  useCitiesQuery,
  useDistrictsQuery,
  useAllCountriesQuery,
} from "./country-api";
import { ApiOptions } from "~/old-app/types/api";

export const useCountries = () => {
  const { data, isFetching, isLoading } = useCountriesQuery();
  return { countries: data?.response.data || [], isLoading, isFetching };
};
export const useAllCountries = (options?: ApiOptions) => {
  const { data, isFetching, isLoading } = useAllCountriesQuery(
    undefined,
    options
  );
  return { allCountries: data?.response.data || [], isLoading, isFetching };
};
export const useDistricts = () => {
  const { district_id } = useConfigSlice();
  const { data, isFetching, isLoading } = useDistrictsQuery(undefined, {
    skip: !district_id,
  });
  return { districts: data?.response.data || [], isLoading, isFetching };
};

export const useCountryStates = (countryId?: string) => {
  const { data, isFetching, isLoading } = useCountryStateQuery(countryId, {
    skip: !countryId,
  });
  return { states: data?.response.data || [], isLoading, isFetching };
};

export const useStateCities = (stateId?: string) => {
  const { data, isFetching, isLoading } = useStateCitiesQuery(stateId, {
    skip: !stateId,
  });
  return { cities: data?.response.data || [], isLoading, isFetching };
};

export const useCities = () => {
  const { data, isFetching, isLoading } = useCitiesQuery();
  return { cities: data?.response?.data || [], isLoading, isFetching };
};
